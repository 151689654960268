import { Form, Input, Button, Checkbox, Card, message, Row, Col } from "antd";
import { useLayoutEffect, useState } from "react";
import { getAuth } from "../utitlities/request";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import LogoSVG from '../assets/entitled-login.svg'; // Import your SVG file
import EntitledLogoSVG from '../assets/entitled.svg'; // Import your SVG file
import '../styles/buttons.css';

export const Demo = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const onFinish = async () => {
    if (username && password) {
      const result = await getAuth(username, password);
      if (result !== "error") {
        localStorage.setItem("token", JSON.stringify(result["access_token"]));
        localStorage.setItem("user", JSON.stringify(result["user"]));
        window.location.href = "/masterview";
      } else {
        message.error("Please enter valid credentials");
      }
    } else {
      message.error("Please enter all credentials");
    }
  };
  function updateDimension() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();
    return () => window.removeEventListener("resize", updateDimension);
  }, []);

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col
          span={12}
          style={{
            backgroundImage: "linear-gradient(#52796F, #f0f0f0)",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            paddingTop: '10%'
          }}
        >
          <img src={EntitledLogoSVG} alt="Logo" style={{ width: '50%', height: 'auto' }} />
          <img src={LogoSVG} alt="Logo" style={{ width: '50%', height: 'auto' }} />
        </Col>
        <Col span={12} style={{ background: "#f5f5f5" }}>
          <div style={{ margin: "15%", paddingTop: "5%", paddingBottom:"5%" }}>
            <h2
              style={{
                marginLeft: "10%",
                marginBottom: "3%",
                padding: "3%",
                color: "#52796F",
                textAlign: "center",
                justifyContent: "center",
                fontWeight: "bold"
              }}
            >
            Entitled  Lending Management
            </h2>

            <p
              style={{
                marginLeft: "28%",
                marginBottom: "5%",
                padding: "1%",
                color: "#8c8c8c",
                fontSize: "18px",
              }}
            >
              Please fill out the credentials to log in
            </p>

            <Input
              size="large"
              placeholder="Username"
              prefix={<UserOutlined />}
              style={{ margin: "5%", padding: "2%" }}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              onKeyDown={(e)=>{
                if(e.key == "Enter"){
                   onFinish()
                }
              }}
            />

            <Input
              size="large"
              type="password"
          
              placeholder="Password"
              prefix={<LockOutlined />}
              style={{ margin: "5%", padding: "2%" }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e)=>{
                if(e.key == "Enter"){
                   onFinish() 
                }
              }}
            />

            <Button
              size="large"
              type="primary"
              shape="round"
              className="antd-button"
              style={{ background: "#52796F", width: "100%", margin: "5%", height: "60px", fontSize: "22px" }}
              onClick={onFinish}
            >
              <b>Log In</b>
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );

};

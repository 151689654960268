import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MainReducer } from "../../types";
import type { InputRef } from "antd";
import { Space, Divider, Button, Table, Select, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { FormInstance } from "antd/es/form";
import { CheckCircleFilled, EditTwoTone, DeleteTwoTone, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { insertReferences, updateReferences, deleteResources } from "../../utitlities/request";
import { fetchEmployee } from "../../state/action-creators";
import "../../styles/buttons.css";
const { Option } = Select;

interface Ref_interface {
  key: string;
  name: string;
  phone: number;
  relation: string;
  status: string;
  isEdit: Boolean;
}

interface EditableRowProps {
  index: number;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const References: React.FC = () => {
  const dispatch = useDispatch();
  const employee = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
  });
  const references = employee.references;
  const [referencesMapped, setReferencesMapped] = useState(
    references.map((r: any, index: Number) => ({
      id: r.id,
      key: index,
      name: r.name,
      phone: r.mobile_no,
      relation: r.relation,
      status: r.state,
      isEdit: false,
    }))
  );
  const handleEdit = (record: any) => {
    const newData = referencesMapped.map((item: any) => {
      if (item.id === record.id) {
        return { ...item, isEdit: true };
      }
      return item;
    });
    setReferencesMapped(newData);
  };
  const onChangeRef = async (is_verified: any, id: number) => {
    let data = {
      state: is_verified,
    };
    const result = await updateReferences(data, id);
    dispatch(fetchEmployee(String(employee.id)));
  };
  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        if (record.isEdit) return <input value={text} onChange={(e) => handleInputChange(e.target.value, record, index, 'name')} ></input>
        return <span>{text}</span>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text, record, index) => {
        if (record.isEdit) return <input value={text} onChange={(e) => handleInputChange(e.target.value, record, index, 'phone')} ></input>
        return <span>{text}</span>;
      },
    },
    {
      title: "Relation",
      dataIndex: "relation",
      key: "relation",
      render: (text, record, index) => {
        if (record.isEdit) return <input value={text} onChange={(e) => handleInputChange(e.target.value, record, index, 'relation')} ></input>
        return <span>{text}</span>;
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text, record, index) => (
        <Select
          value={text}
          defaultValue={'PENDING'}
          style={{ width: "75%" }}
          onChange={async (el) => {
            await onChangeRef(el, Number(record.id));
          }}
        >
          {['APPROVED', 'REJECTED'].map((element: any, index: number) => {
            return (
              <Option key={`refstatuses-${element}-${index}`} value={element}>{element}</Option>
            );
          })}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record, index) => (
        <Space size="middle">
          {record.isEdit ? (
            <Button onClick={() => { handleSaveReference(record) }} className="antd-button">Save</Button>
          ) : (
            <EditTwoTone style={{ fontSize: "20px" }}
              onClick={() => handleEdit(record)} />
          )}
        </Space>
      ),
    },
  ];

  const tempRef_obj: any =
  {
    key: "",
    name: "",
    phone: '',
    relation: "",
    status: "",
    isEdit: true,
  }
    ;

  const addNewRef = () => {
    setReferencesMapped([
      ...referencesMapped,
      tempRef_obj
    ])
  }
  const deleteRejectedRefs = async function (emp_id: number, type: string) {
    if (emp_id != -1) {
      const resources = await deleteResources(emp_id, type);
      if (resources.statusCode == 200) {
        message.success(resources.message);
        // add loading spinner
        dispatch(fetchEmployee(String(emp_id)));
      } else message.error(resources.message);
    }
  }
  const handleSaveReference = async function (record: any) {
    if (record.id) {
      let data = {
        name: record.name,
        mobile_no: record.phone,
        relation: record.relation,
        state: record.status,
      };
      const result = await updateReferences(data, record.id);
      dispatch(fetchEmployee(String(employee.id)));
    }
    else {
      let data = {
        address: record.address,
        is_verified: record.verified,
        mobile_no: record.phone,
        name: record.name,
        relation: record.relation,
        emp_id: employee.id,
      };
      await insertReferences(data);
    }
    dispatch(fetchEmployee(String(employee.id)));
  }
  useEffect(() => {
    setReferencesMapped(references.map((r: any, index: Number) => ({
      id: r.id,
      key: index,
      name: r.name,
      phone: r.mobile_no,
      relation: r.relation,
      status: r.state,
      isEdit: false,
    })))
  }, [references])
  const handleInputChange = (value: any, record: any, index: number, field: string) => {
    const updatedDataSource = [...referencesMapped];
    updatedDataSource[index] = { ...record, [field]: value };
    setReferencesMapped(updatedDataSource);
  };
  return (
    <div className="emp-info-form">
      <div className="title">References</div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      <Table columns={columns} dataSource={referencesMapped} />
      <Button
        icon={<PlusOutlined />}
        type="primary"
        className="antd-button"
        style={{
          marginLeft: "20px",
          backgroundColor: "#1B875A",
          border: 0
        }}
        onClick={addNewRef}
      >
        Upload Reference
      </Button>
      <Button
        type="primary"
        className="antd-button"
        style={{
          background: "red",
          borderColor: "red",
          color: "white",
          borderRadius: '2px 2px 2px 2px',
          marginLeft: '10px',
        }}
        icon={<DeleteOutlined style={{ fontSize: '16px' }} />}
        onClick={() => deleteRejectedRefs(employee.id || -1, 'reference')}
      >
        Delete All Rejected References
      </Button>
    </div>
  );
};
export default References;

import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Button, Typography, message } from "antd";
import { BankOutlined, HighlightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import {
  Personal,
  Employment,
  Salary,
  BankDetails,
  Data,
  DocumentSchema,
  MainReducer,
  ExistingLoanRequest,
  ExistingLoan,
  BankStatementData,
} from "../../types";

import { useLocation } from "react-router-dom";

import { analyseStatements, bankValidate, abbSave, BankDetailsUpdateDatabase, generateCibil, BankDetailsFetch, pushToThirdParty, createSingleCharge } from "../../utitlities/request";
import { fetchArthmate, fetchEmployee, updateEmployee, fetchLoan } from "../../state/action-creators";
import NBFCData from "./NBFCData";
import ChargeModal from "../modals/createCharge";
import "../../styles/buttons.css";


const { Text } = Typography;

const BankInformation: React.FC = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");
  const employee = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
  });
  const loan = useSelector((state: MainReducer) => {
    return state.loan.loan.body;
  });
  const [third_party_status, setThirdPartyStatus] = useState(false);
  useEffect(() => {
    setThirdPartyStatus(loan.third_party_status);
  }, [loan]);
  const bank_acc_status_map: Record<number, string> = {
    0: "NOT CHECKED",
    1: "VALID BANK ACCOUNT",
    2: "INVALID BANK ACCOUNT",
  };

  const is_bank_account_verified = Number(employee["is_bank_account_verified"]);
  const bank_acc_status = bank_acc_status_map[is_bank_account_verified] || '';

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleCreate = async (amount: number | undefined, date: any) => {

    try {
      const formattedDate = date.toISOString().split('T')[0];
      let params = {
        loan_id: loan?.ops_id,
        amount: amount,
        date: formattedDate
      }
      const response = await createSingleCharge(params);
      setModalVisible(false);

    }
    catch (error) {

      message.error('Charge could not be created')
      console.log(error);
      setModalVisible(false);
    }



    // Close the modal
  };

  const request_id = new URLSearchParams(search).get("request_id");
  const loan_id = new URLSearchParams(search).get("loan_id");
  const bankDetail = employee.bank_details;
  const [cibilloading, setCibilLoading] = useState(false);
  const [thirdPartyLoading, setThirdPartyLoading] = useState(false);

  async function callValidateBank() {
    // setLoading(true);
    await bankValidate(request_id);
    // setLoading(false);
  }
  async function sendLoanToThirdParty() {
    try {
      setThirdPartyLoading(true);
      await pushToThirdParty(loan_id);
      dispatch(fetchLoan(Number(loan_id)));
      setThirdPartyLoading(false);
    } catch (error) {
      setThirdPartyLoading(false);
      message.error('Failed to Push to third Party')
    }

  }

  async function callCibil() {
    setCibilLoading(true)
    await generateCibil(loan_id);
    setCibilLoading(false)
    dispatch(fetchArthmate(loan_id));
  }

  const buttonStyle = {
    backgroundColor: '#1B875A',
    border: 'none',
    color: 'white',
  };

  return (
    <div className="emp-info-form">
      <div className="title">Bank & Financial</div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      <div className="form01">
        <div>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Bank Name</div>
              <Text style={{ fontSize: "14px" }}>{employee.bank_details.bank_name}</Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Branch</div>
              <Text style={{ fontSize: "14px" }}>{employee.bank_details.bank_branch}</Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Account Number</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      bank_details: {
                        ...employee.bank_details,
                        account_no: val
                      }
                    }))
                  },
                }}
              >
                {employee.bank_details.account_no}
              </Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">State</div>
              <Text style={{ fontSize: "14px" }}>{employee.bank_details.bank_state}</Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Bank Code</div>
              <Text style={{ fontSize: "14px" }}>{employee.bank_details.bank_code}</Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Beneficiary Name</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      bank_details: {
                        ...employee.bank_details,
                        beneficiary_name: val,
                      }
                    }))
                  },
                }}
              >
                {employee.bank_details.beneficiary_name}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">IFSC Code</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: async (val) => {
                    const { BANK, BRANCH, STATE } = await BankDetailsFetch(val);
                    const result = await BankDetailsUpdateDatabase(val);
                    dispatch(updateEmployee({
                      ...employee,
                      bank_details: {
                        ...employee.bank_details,
                        ifsc_code: val,
                        bank_name: BANK,
                        bank_branch: BRANCH,
                        bank_state: STATE
                      }
                    }));
                  },
                }}
              >
                {employee.bank_details.ifsc_code}
              </Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Bank Statement Password</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      bank_details: {
                        ...employee.bank_details,
                        bank_statement_password: val
                      }
                    }))
                  },
                }}
              >
                {employee.bank_details.bank_statement_password}
              </Text>
            </Col>
          </Row>
          {type === 'appraisal' && <NBFCData />}
          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">ABB</div>
              <Text style={{ fontSize: "14px" }}>{bankDetail.abb}</Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">Latest Cibil</div>
              <Text style={{ fontSize: "14px" }}>{employee.bank_details.cibil_score}</Text>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">ABB Required</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    abbSave(
                      { balance: Number(val) },
                      employee.id || ""
                    );
                    dispatch(fetchEmployee(String(employee.id)));
                  },
                }}
              >
                {String(bankDetail?.abb_required)}
              </Text>
            </Col>
            <Col xs={24} xl={12} className="info-input-row">
              <div className="label">VPA</div>
              <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val) => {
                    dispatch(updateEmployee({
                      ...employee,
                      bank_details: {
                        ...employee.bank_details,
                        vpa: val
                      }
                    }))
                  },
                }}
              >
                {String(bankDetail?.vpa)}
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12} xl={12} className="info-input-row">
              <div className="label">Bank Account Status</div>
              <Text style={{ fontSize: "14px" }}>{bank_acc_status}</Text>
            </Col>
            <Col xs={12} xl={12} className="info-input-row">
              <div className="label">Third Party Status</div>
              <Text style={{ fontSize: "14px" }}>{third_party_status ? 'Yes' : 'No'}</Text>
            </Col>
          </Row>
        </div>
        {type === 'appraisal' && <><div>
          <Button
            icon={<BankOutlined />}
            type="primary"
            className="antd-button"
            style={buttonStyle}
            onClick={async () => {
              await analyseStatements(employee.id);
            }}
          >
            Analyze Statement
          </Button>
          <Button
            icon={<BankOutlined />}
            type="primary"
            className="antd-button"
            style={{ ...buttonStyle, marginLeft: "20px" }}
            loading={cibilloading}
            onClick={() => {
              callCibil();
            }}
          >
            Generate CIBIL
          </Button>
          <Button
            icon={<BankOutlined />}
            type="primary"
            className="antd-button"
            style={{ ...buttonStyle, marginLeft: "20px" }}
            onClick={() => {
              callValidateBank();
            }}
          >
            Validate Bank
          </Button>
          <Button
            icon={<BankOutlined />}
            type="primary"
            className="antd-button"
            style={{ ...buttonStyle, marginLeft: "20px", marginRight: "20px" }}
            onClick={() => {
              sendLoanToThirdParty();
            }}
            loading={thirdPartyLoading}
            disabled={third_party_status}
          >
            Push Third Party
          </Button>
          <Button
            icon={<BankOutlined />}
            type="primary"
            className="antd-button"
            style={{ ...buttonStyle, marginTop: "10px" }}
            onClick={showModal}
          >
            Create Charge
          </Button>
        </div>
          <ChargeModal
            visible={modalVisible}
            onCancel={handleCancel}
            onCreate={handleCreate}
          />
        </>
        }
      </div>
    </div>
  );
};
export default BankInformation;

import React,{useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { Divider, Row, Col, DatePicker, Typography, Table, Space, Button, Input } from "antd";
import {HighlightOutlined, DeleteTwoTone, PlusOutlined} from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import type { ColumnsType } from "antd/es/table";
import { updateEmployment, deleteSalary, addSalary } from "../../utitlities/request";
import moment from "moment";
import {
  Personal,
  Salary,
  BankDetails,
  Data,
  DocumentSchema,
  MainReducer,
  ExistingLoanRequest,
  ExistingLoan,
  BankStatementData,
} from "../../types";
import {
  fetchLoanRequest,
  fetchEmployee,
  fetchLoan,
  reset,
  fetchLogs,
  fetchComments,
  fetchArthmate,
} from "../../state/action-creators";
import "../../styles/buttons.css";

const { Text } = Typography;

const Employment: React.FC = () => {
  const search = useLocation().search;
  const employee_id = new URLSearchParams(search).get("employee_id");
  const dispatch = useDispatch();
  const employee:any = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
  }) || {};
  const [employment_info, setEmployment_info] = useState(employee.employment);
  const [salaries, setSalaries] = useState(employee.salaries.map((s:any,index:Number) => ({
    id:s.id,
    payout_date: s.payout_date ? s.payout_date.split("T")[0] : "Not Available",
    amount: s.amount || 0,
    isEdit:false
  })));
  const handleSaveSalary = async function(record:any) {
    const {payout_date, amount} = record;
    const res = await addSalary(employee.id, payout_date, amount);
    dispatch(fetchEmployee(String(employee_id)));
  }
  useEffect(()=>{
    setSalaries(employee.salaries.map((s:any,index:Number) => ({
      id:s.id,
      payout_date: s.payout_date ? s.payout_date.split("T")[0] : "Not Available",
      amount: s.amount || 0,
      isEdit:false
    })))
  },[employment_info,employee, employee.salaries])
  const handleDeleteSalary = async function(recordId:Number, indexToRemove:number){
    //setloading;\
    const result = await deleteSalary(recordId);
    dispatch(fetchEmployee(String(employee_id)));
  }
  const handleInputChange = (value:any, record:any, index:number, field:string) => {
    const updatedDataSource = [...salaries];
    updatedDataSource[index] = { ...record, [field]: value };
    setSalaries(updatedDataSource);
  };
  const columns: ColumnsType<any> = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text,record,index) => {
        if(record.isEdit)return <input value={text} onChange={(e) => handleInputChange(e.target.value, record, index, 'amount')} ></input>
        return <span>{text}</span>;
      },
    },
    {
      title: "PayOut Date",
      dataIndex: "payout_date",
      key: "payout_date",
      render: (text,record,index) => {
        const payoutDate = moment(record.payout_date);
        if(record.isEdit){
          return <Input
            placeholder="salary_date"
            type="date"
            value={record.payout_date}
            onChange={(e) => {
              handleInputChange(e.target.value, record, index, 'payout_date')
            }}
          />
        }
        return <span>{text}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record, index) => (
        <Space size="middle">
          {record.isEdit ? (
            <Button onClick={() => {handleSaveSalary(record)}} className="antd-button">Save</Button>
          ) : (
            <DeleteTwoTone twoToneColor="#b53328" style={{ fontSize: "20px" }}
             onClick={ () => { handleDeleteSalary(record.id, index);}} />
          )}
        </Space>
      ),
    },
  ];

  async function updateEmploymentSingle(key: string) {
    try {
      // setVisible(false);
      let data = {
        date_of_joining: employment_info.date_of_joinig,
        designation: employment_info.designation,
        //monthly_salary: Number(empsalary),
        work_location: employment_info.work_location,
        job_type: employment_info.job_type,
        department: employment_info.department,
        deputed_at: employment_info.deputed_at,
      };
      {
        // @ts-ignore
        const result = await updateEmployment(key, data);
        // setcallEmployee(Math.random);
      }
    } catch (err) {
      console.error("could not update data",err);
    }
  }

  useEffect(()=>{
    updateEmploymentSingle(String(employment_info?.id))
  },[employment_info])
  const newSalary_obj: any = {
    amount:'',
    payout_date:'',
    isEdit:true,
  }
  const addNewSalary = function () {
    setSalaries([
      ...salaries,
      newSalary_obj
    ])
  }
  return (
    <div className="emp-info-form">
      <div className="title">Employment</div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      <div className="form01">
        <Row gutter={24}>
          <Col xs={24} xl={12} className="info-input-row">
            <div className="label">Employer Name</div>
            <Text style={{ fontSize: "14px" }}>{employment_info?.employer_name}</Text>
          </Col>
          <Col xs={24} xl={12} className="info-input-row">
            <div className="label">Designation</div>
            <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val)=>{setEmployment_info({
                    ...employment_info,
                    designation:val
                  })},
                }}
              >
                {employment_info?.designation}
              </Text>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} className="info-input-row">
            <div className="label">Department Name</div>
            <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val)=>{setEmployment_info({
                    ...employment_info,
                    department:val
                  })},
                }}
              >
                {employment_info?.department}
              </Text>
          </Col>
          <Col xs={24} xl={12} className="info-input-row">
            <div className="label">Job Type</div>
            <Text
                style={{ fontSize: "14px" }}
                editable={{
                  icon: <HighlightOutlined />,
                  tooltip: "click to edit text",
                  onChange: (val)=>{setEmployment_info({
                    ...employment_info,
                    job_type:val
                  })},
                }}
              >
                {employment_info?.job_type}
              </Text>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} className="info-input-row">
            <div className="label">Date of Joining</div>
            <Input
              type="date"
              value={employment_info.date_of_joinig != null ? employment_info.date_of_joinig.split("T")[0] : " "}
              onChange={(e) => {
                setEmployment_info({
                  ...employment_info,
                  date_of_joinig:e.target.value
                })
              }}
            />
          </Col>
          <Col xs={24} xl={12} className="info-input-row">
            <div className="label">Avg Salary</div>
            <Text style={{ fontSize: "14px" }}>&#x20b9; {employment_info.salary}</Text>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} xl={12} className="info-input-row">
            <div className="label">Work Location</div>
            <Text
              style={{ fontSize: "14px" }}
              editable={{
                icon: <HighlightOutlined />,
                tooltip: "click to edit text",
                onChange: (val)=>{setEmployment_info({
                  ...employment_info,
                  work_location:val
                })},
              }}
            >
              {employment_info?.work_location}
            </Text>
          </Col>
          <Col xs={24} xl={12} className="info-input-row">
            <div className="label">Deputed At from OCR</div>
            <Text
              style={{ fontSize: "14px" }}
              editable={{
                icon: <HighlightOutlined />,
                tooltip: "click to edit text",
                onChange: (val)=>{setEmployment_info({
                  ...employment_info,
                  deputed_at:val
                })},
              }}
            >
              {employment_info?.deputed_at}
            </Text>
          </Col>
        </Row>
        <Divider orientation="left" style={{ borderColor: "#E5E7EA", margin: "20px 0px" }}>Salaries</Divider> 
        <Table columns={columns} dataSource={salaries} /> 
        <Button
          icon={<PlusOutlined />}
          type="primary"
          className="antd-button"
          style={{
            marginLeft: "0px",
            backgroundColor: "#1B875A",
            border:0
          }}
          onClick={addNewSalary}
        >
          Upload Salary
        </Button>
      </div>
    </div>
  );
};
export default Employment;

import { message } from "antd";
import axios from "axios";
import moment from "moment";
import { config } from "../config";

function getToken() {
  return {
    //@ts-ignore
    headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}` }
    }
}
export const employeeSave = async (data: any, id: string) => {
  try {
    const result = await axios.put(
      `${config.base_url}/employee/${id}`,
      data,
      getToken()
    );
    //await logData("update_employee",{data,id})
    return result["data"];
  } catch (err) {
    //  window.location.href = "/no_cred"
  }
};

export const abbSave = async (data: any, id: string) => {
  try {
    const result = await axios.put(
      `${config.base_url}/employee/arthmate/${id}`,
      data,
      getToken()
    );
    //await logData("update_employee",{data,id})
    return result["data"];
  } catch (err) {
    //  window.location.href = "/no_cred"
  }
};
export const employeeDetails = async (id: string) => {
  try {
    const res = await axios.get(
      `${config.base_url}/employee/details/${id}`,
      getToken()
    );
    return res["data"];
  } catch (err) {
    // window.location.href = "/no_cred";
  }
};
export const getConfig = async () => {
  try {
    const result = await axios.get(
      `${config.base_url}/utils/config`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const getOperationsListApiCall = async (
  request: any
  ) => {
  try {
    const _request = Object.fromEntries(Object.entries(request).filter(([_, v]) => v !== null && v!== "" && v!== undefined));
    const result = await axios.get(
      // @ts-ignore
      `${config.base_url}/loan?${new URLSearchParams(_request).toString()}`,
      getToken(),
    );
    return result;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const getAppraisalListApiCall  = async (
  request: any
  ) => {
  try {
    const _request = Object.fromEntries(Object.entries(request).filter(([_, v]) => v !== null && v!== "" && v!== undefined));
    const result = await axios.get(
      // @ts-ignore
      `${config.base_url}/loan/appraised/all?${new URLSearchParams(_request).toString()}`,
      getToken(),
    );
    return result;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const getMasterViewListApiCall  = async (
  request: any
  ) => {
  try {
    const _request = Object.fromEntries(Object.entries(request).filter(([_, v]) => v !== null && v!== "" && v!== undefined));
    const result = await axios.get(
      // @ts-ignore
      `${config.base_url}/loan/appraisal/masterview-universal?${new URLSearchParams(_request).toString()}`,
      getToken(),
    );
    return result;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const getLoans = async (
  current_page: number,
  size: number,
  status: Array<string>,
  userid: any,
  user_role: any,
  start_date?: string,
  end_date?: string,
  whatsapp_no?: any,
  employee_id?: any,
  requestId?: any,
  borrower_id?: any,
  company?: any,
  mobile_number?: any,
  assignedTo?: any,
  maritalStatus?: any,
  gender?: any
) => {
  try {
   // if (user_role == "admin") {
      userid = "all";
    //}

    end_date = moment(end_date).add(1, "days").format("YYYY-MM-DD");
    let queryString = `?page=${current_page}&limit=${size}&status=${status}&userid=${userid}&start_date=${start_date}&end_date=${end_date}`;

    if (whatsapp_no) {
      queryString = queryString + `&whatsapp_no=${whatsapp_no}`;
    }

    if (employee_id) {
      queryString = queryString + `&emp_id=${employee_id}`;
    }

    if (requestId) {
      queryString = queryString + `&request_id=${requestId}`;
    }

    if (borrower_id) {
      queryString = queryString + `&borrower_id=${borrower_id}`;
    }

    if (company) {
      queryString = queryString + `&company=${company}`;
    }

    if (mobile_number) {
      queryString = queryString + `&mobile_no=${mobile_number}`;
    }

    if (assignedTo) {
      queryString = queryString + `&user_name=${assignedTo}`;
    }

    if (maritalStatus) {
      queryString = queryString + `&marital_status=${maritalStatus}`;
    }

    if (gender) {
      queryString = queryString + `&gender=${gender}`;
    }

    const result = await axios.get(
      `${config.base_url}/loan${queryString}`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const getExistingLoans = async (internalEmpId: string, view = "detail" ) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/existing/loans?emp_id=${internalEmpId}&view=${view}`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    // window.location.href = "/no_cred";
  }
};
export const getCibilModelScore = async (loanId:any ) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/cibil_model_score/loans?loan_id=${loanId}`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    throw err;
    // window.location.href = "/no_cred";
  }
};
export const getPendingItems = async (mobile: string ) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/pendingitems/loan?mobile_no=${mobile}`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    throw err;
    // window.location.href = "/no_cred";
  }
};

export const getExistingLoanRequests = async (internalEmpId: string) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/existing/loanRequests?emp_id=${internalEmpId}`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    // window.location.href = "/no_cred";
  }
};

export const getBankStatementData = async (internalEmpId: string) => {
  try {    
    const result = await axios.get(
      `${config.base_url}/loan/digitap/bankStatementData?emp_id=${internalEmpId}`,
      getToken()
    );    
    return result["data"];
  } catch (err) {
    // window.location.href = "/no_cred";
  }
};

export const getLoanswithStatus = async (status: string) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan?page=1&limit=10&status=${status}`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};
export const updateBulk = async (data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/loan/updateBulk`,
      data,
      getToken()
    );
    // await logData("update_bulk_loan_request_status",data)
    return result["data"];
  } catch (err) {
    await createSlackAlert(err, "UPDATE LOAN REQUEST");
    window.location.href = "/no_cred";
  }
};
export const updateBulkAppraised = async (data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/loan/appraised/updateBulk`,
      data,
      getToken()
    );
    // await logData("update_bulk_loan_status",data)
    return result["data"];
  } catch (err) {
    await createSlackAlert(err, "UPDATE LOAN ");
    window.location.href = "/no_cred";
  }
};
export const appraisal = async (
  current_page: number,
  size: number,
  status: Array<string>,
  userid: any,
  user_role: any,
  start_date: string,
  end_date: string,
  whatsapp_no?: string,
  borrower_id?: any,
  loan_id?: any,
  pan?: any,
  companyEmpId?: any,
  mobile_number?: any,
  company?: any,
  nbfc_name?: any,
  esignStatus?: any,
  enachStatus?: any,
  requestAssignTo?: any,
  loanAssignTo?: any,
  nbfcStatus?: any,
  abb?: any,
  approvedAmount?: any
) => {
  try {
    //if (user_role == "admin") {
      userid = "all";
    //}
    end_date = moment(end_date).add(1, "days").format("YYYY-MM-DD");
    let url = `appraised/all?page=${current_page}&limit=${size}&status=${status}&userid=${userid}&start_date=${start_date}&end_date=${end_date}`;
    if (whatsapp_no) {
      url = url + `&whatsapp_no=${whatsapp_no}`;
    }

    if (loan_id) {
      url = url + `&loan_id=${loan_id}`;
    }

    if (pan) {
      url = url + `&pan=${pan}`;
    }

    if (borrower_id) {
      url = url + `&borrower_id=${borrower_id}`;
    }

    if (companyEmpId) {
      url = url + `&emp_id=${companyEmpId}`;
    }

    if (company) {
      url = url + `&company=${company}`;
    }

    if (mobile_number) {
      url = url + `&mobile_no=${mobile_number}`;
    }

    if (nbfc_name) {
      url = url + `&nbfc=${nbfc_name}`;
    }

    if (esignStatus) {
      url = url + `&esign_status=${esignStatus}`;
    }

    if (enachStatus) {
      url = url + `&enach_status=${enachStatus}`;
    }

    if (requestAssignTo) {
      url = url + `&request_user_name=${requestAssignTo}`;
    }

    if (loanAssignTo) {
      url = url + `&loan_user_name=${loanAssignTo}`;
    }
    if (nbfcStatus) {
      url = url + `&nbfc_status=${nbfcStatus}`;
    }
    if (abb) {
      url = url + `&abb=${abb}`;
    }
    if (approvedAmount) {
      url = url + `&approved_amount=${approvedAmount}`;
    }

    // if(assignedTo) {
    //   queryString = queryString+`&user_name=${assignedTo}`;
    // };

    // if(maritalStatus) {
    //   queryString = queryString+`&marital_status=${maritalStatus}`;
    // };

    // if(gender) {
    //   queryString = queryString+`&gender=${gender}`;
    // };

    const result = await axios.get(
      `${config.base_url}/loan/${url}`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};
export const enach = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/loan/create-sources-with-queue`,
      data,
      getToken()
    );
    if (result["data"]["statusCode"] === 500) {
      message.error("Could not queue Enach");
    } else {
      message.success("Enach Request Sucessfully Queued");
    }
    // await logData("generate_enach",data)
    return result["data"];
  } catch (err) {
    let status = JSON.parse(JSON.stringify(err))["status"];
    if (status === 401) {
      window.location.href = "/no_cred";
    } else {
      message.error("Could not queue Enach Request");
    }
  }
};
export const esign = async (data: any) => {
  try {
    // const result = await axios.post(
    //   `${config.base_url}/loan/create-esign`, data, getToken()
    // );
    const result = await axios.post(
      `${config.base_url}/loan/create-leegality-esign`,
      data,
      getToken()
    );
    message.success("Esign Request Sucessfully Queued");
    //  await logData("generate_esign",data)
    return result["data"];
  } catch (err) {
    let status = JSON.parse(JSON.stringify(err))["status"];
    if (status === 401) {
      window.location.href = "/no_cred";
    } else {
      message.error("Could not generate esign");
    }
  }
};
export const verifySelfieAadhar = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/employee/document/verifyphoto`,
      data,
      getToken()
    );
    // await logData("update_document",{data,document_id})
    console.log('>>>>>', result)
    return result["data"];
  } catch (err) {

    console.log('err', err)
    // window.location.href = "/no_cred";
  }
};
export const fileUpload = async (data: any, document_id: number) => {
  try {
    const result = await axios.put(
      `${config.base_url}/employee/document/${document_id}`,
      data,
      getToken()
    );
    // await logData("update_document",{data,document_id})
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const s3fileUpload = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/utils/upload`,
      data,
      getToken()
    );
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const fileUploadNew = async (data: any, employee_id: number) => {
  try {
    const result = await axios.post(
      `${config.base_url}/employee/document`,
      data,
      getToken()
    );
    // await logData("add_document",data)
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const updateReferences = async (data: any, document_id: number) => {
  try {
    const result = await axios.put(
      `${config.base_url}/loan/reference/${document_id}`,
      data,
      getToken()
    );
    //await logData("update_reference",{data,document_id})
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const insertReferences = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/loan/reference-insert`,
      data,
      getToken()
    );
    // await logData("add_reference",data)
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const getLoanRequest = async (loan_id: number) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/${loan_id}`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};
export const updateEmployment = async (employee_id: number, data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/employee/employment/${employee_id}`,
      data,
      getToken()
    );
    // await logData("update_employment",{data,employee_id})
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const deleteDocument = async (id: number) => {
  try {
    const result = await axios.put(
      `${config.base_url}/employee/document/delete/${id}`,
      {},
      getToken()
    );
    // await logData("delete_document",{id})
    return result["data"];
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const updateAllRowsConstant = async (data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/repayment-schedule`,
      data,
      getToken()
    );
    // await logData("update_repayment_loan_data",data)
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};
export const createResourceAutopay = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/loan/create-sources-with-upi`,
      data,
      getToken()
    );
    // await logData("update_repayment_loan_data",data)
    return result["data"];
  } catch (err) {
    // window.location.href = "/no_cred";
  }
};

export const deleteRepayment = async (record_id: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/repayment/remove/${record_id}`,
      record_id,
      getToken()
    );
    return result["data"];
  } catch (err) {
    console.log(err)
    // window.location.href = "/no_cred";
  }
};

export const getAuth = async (username: string, password: string) => {
  try {
    const result = await axios.post(`${config.base_url}/auth/login`, {
      username,
      password,
    });
    //@ts-ignore
    //await logData("login",{username,password},String(result["data"].user.id))
    return result["data"];
  } catch (err) {
    return "error";
  }
};
export const logData = async (
  event_type: string,
  event_data: object,
  user_id?: string
) => {
  try {
    if (!user_id) {
      //@ts-ignore
      user_id = String(JSON.parse(localStorage.getItem("user")).id);
    }
    const result = await axios.put(config.kinesis_url, {
      Data: {
        user_id: user_id,
        event_type: event_type,
        partition_name: "ops_audit_logs",
        event_data: JSON.stringify(event_data),
        event_timestamp: Math.floor(Date.now() / 1000),
      },
      PartitionKey: "partition_name",
    });
    return result["data"];
  } catch (err) {
    return "error";
  }
};

export const getOriginalLoanData = async (loan_id: number) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/appraised-loan/${loan_id}`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const updateLoanRequest = async (loan_request_id: any, data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/loan/${loan_request_id}`,
      data,
      getToken()
    );
    // await logData("update_loan_request",{data,loan_request_id})
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};
export const getUsers = async () => {
  const result = await axios.get(`${config.base_url}/user`, getToken());
  return result["data"];
};

export const updateBulkUser = async (data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/loan/loan-request/assignUser`,
      data,
      getToken()
    );
    // await logData("assign_user_loan_request",data)
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const updateBulkLoanAssignUser = async (data: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/loan/appraisal/assignUser`,
      data,
      getToken()
    );
    //  await logData("assign_user_loan",data)
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const nbfc = async () => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/utils/nbfc`,
      getToken()
    );
    return result["data"];
  } catch (err) {
    window.location.href = "/no_cred";
  }
};


export const getLoanMetricsRows = async (data: any) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/utils/loanMetricsReport`,{
        params: data,
        headers: getToken().headers
      }
    );
    return result["data"];
  } catch (err) {
    console.log('error while generating report', err)
    message.error("Error while generating report");
    // window.location.href = "/no_cred";
  }
};

export const getChargeMetricsRows = async (data: any) => {
  try {
    const result = await axios.get(
      `${config.base_url}/loan/utils/chargeReport`,{
        params: data,
        headers: getToken().headers
      }
    );
    return result["data"];
  } catch (err) {
    console.log('error while generating report', err)
    message.error("Error while generating report");
    // window.location.href = "/no_cred";
  }
};

export const appraisalNotifications = async (data: any) => {
  try {
    const result: any = await axios.post(
      `${config.base_url}/loan/appraisal-notify`,
      data,
      getToken()
    );
    if (result["data"]["statusCode"] === 500) {
      message.error("Could not send Appraisal Notifications");
    } else {
      message.success("Sent Notifications");
    }
    //  await logData("notify_enach",data)
    return result["data"];
  } catch (err) {
    let status = JSON.parse(JSON.stringify(err))["status"];
    if (status === 401) {
      window.location.href = "/no_cred";
    } else {
      message.error("Could not send Appraisal Notifications");
    }
  }
};

export const EsignNotifications = async (data: any) => {
  try {
    const result: any = await axios.post(
      `${config.base_url}/loan/esign-notify`,
      data,
      getToken()
    );
    if (result["data"]["statusCode"] === 500) {
      message.error("Could not send Esign Notifications");
    } else {
      message.success("Sent Notifications");
    }
    //  await logData("notify_esign",data)
    return result["data"];
  } catch (err) {
    let status = JSON.parse(JSON.stringify(err))["status"];
    if (status === 401) {
      window.location.href = "/no_cred";
    } else {
      message.error("Could not send Esign Notifications");
    }
  }
};

export const createExport = async (
  data: any,
  start_date: any,
  end_date: any,
  status: any
) => {
  try {
    const headers: any = getToken();
    {
      //@ts-ignore
      headers["headers"]["responseType"] = "blob";
    }
    const result = await axios.post(
      `${config.base_url}/loan/utils/create-export?nbfc_id=${data}&start_date=${start_date}&end_date=${end_date}&status=${status}`,
      {},
      headers
    );
    //  await logData("export_nbfc",{data,start_date,end_date,status})
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${new Date().toISOString()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    return "success";
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const createComplianceExport = async (
  start_date: any,
  end_date: any
) => {
  try {
    const headers: any = getToken();
    {
      //@ts-ignore
      headers["headers"]["responseType"] = "blob";
    }
    const result = await axios.post(
      `${config.base_url}/loan/utils/create-compliance-export?start_date=${start_date}&end_date=${end_date}`,
      {},
      headers
    );
    //  await logData("export_nbfc",{data,start_date,end_date,status})
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${new Date().toISOString()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    return "success";
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const BankDetailsFetch = async (ifsc: any) => {
  try {
    const result = await axios.get(`https://ifsc.razorpay.com/${ifsc}`);
    return result["data"];
  } catch (err) {
    return {
      BANK: "",
      BRANCH: "",
      STATE: "",
    };
  }
};

export const BankDetailsUpdateDatabase = async (ifsc: any) => {
  try {
    const result = await axios.put(
      `${config.base_url}/employee/ifsc/${ifsc}`,
      {},
      getToken()
    );
    return result;
  } catch (err) {
    message.error("Could Set Ifsc Details");
  }
};

export const PostalCodeApi = async (pincode: string) => {
  //https://api.postalpincode.in/pincode/${pincode}
  try {
    const result = await axios.get(
      `https://prod.entitled.co.in/pin-code?pin=${pincode}`
    );
    let data = result["data"]["data"];
    if (data) {
      return data;
    } else {
      return {
        district: "",
        state: "",
      };
    }
  } catch (err) {
    return {
      district: "",
      state: "",
    };
  }
};

export const getExportForEnachEsign = async (loanIds: any) => {
  try {
    const { data } = await axios.post(
      `${config.base_url}/loan/appraisal/get-export`,
      loanIds,
      getToken()
    );
    return data;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const getLoanComments = async (request_id: any) => {
  try {
    const { data } = await axios.get(
      `${config.base_url}/loan/appraisal/comment/${request_id}`,
      getToken()
    );
    return data;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const getStatusLogs = async (request_id: any) => {
  try {
    const { data } = await axios.get(
      `${config.base_url}/loan/appraisal/status-logs/${request_id}`,
      getToken()
    );
    return data;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const getActivityStream = async (entity_type:any, entity_id:any) => {
  try {
    const { data } = await axios.get(
      `${config.base_url}/loan/appraisal/activity_stream/${entity_type}/${entity_id}`,
      getToken()
    );
    return data;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const addLoanComment = async (
  author_id: any,
  comment: any,
  request_id: any
) => {
  try {
    const { data } = await axios.post(
      `${config.base_url}/loan/appraisal/comment`,
      {
        author_id: author_id,
        comment: comment,
        request_id: Number(request_id),
      },
      getToken()
    );
    return data;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const addSalary = async (
  emp_id: any,
  date_of_credit: any,
  salary_paid: any
) => {
  try {
    const { data } = await axios.post(
      `${config.base_url}/employee-salary`,
      { date_of_credit, salary_paid, emp_id },
      getToken()
    );
    return data;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const analyseStatements = async (emp_id: any) => {
  try {
    const { data } = await axios.post(
      `${config.base_url}/employee/analyzeStatement`,
      { emp_id: emp_id },
      getToken()
    );
    message.success("Analaysis Request Queued Successfully");
    return data;
  } catch (err) {
    message.error("Analaysis Request Failed");
    // window.location.href = "/no_cred"
  }
};

export const generateCibil = async (loan_id: any) => {
  try {
    const { data } = await axios.post(
      `${config.base_url}/loan/arthmate`,
      { loan_id: loan_id },
      getToken()
    );
    message.success("Cibil Generated Successfully");
    return data;
  } catch (err) {
    message.error("Cibil Request Failed");
    // window.location.href = "/no_cred"
  }
};

export const getCibil = async (loan_id: any) => {
  try {
    const { data } = await axios.get(
      `${config.base_url}/loan/arthmate/${loan_id}`,
      getToken()
    );
    //message.success("Cibil Generated Successfully");
    return data;
  } catch (err) {
    message.error("Cibil Fetch Failed");
    // window.location.href = "/no_cred"
  }
};

export const deleteSalary = async (salary_id: any) => {
  try {
    const { data } = await axios.delete(
      `${config.base_url}/employee-salary/${salary_id}`
    );
    return data;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};
// {
//   "queue":"prepareZipFiles",
//   "body":{
//   "loan_ids":["1876"],
//   "email":"ankan.m@entitled.co.in"
// }
// }

export const exportZipFiles = async (loan_ids: any) => {
  try {
    // @ts-ignore
    let email = JSON.parse(localStorage.getItem("user"))["user_name"];
    const { data } = await axios.post(
      `${config.base_url}/utils/queue`,
      {
        queue: "prepareZipFiles",
        body: {
          loan_ids: loan_ids,
          email: email,
        },
      },
      getToken()
    );
    return data;
  } catch (err) {
    message.error("Could not export");
    // window.location.href = "/no_cred"
  }
};

export const getRepayments = async (
  page: number,
  limit: number,
  start_date: any,
  end_date: any
) => {
  try {
    limit = 50000;
    const { data } = await axios.get(
      `${config.base_url}/repayment?page=${page}&limit=${limit}&start_date=${start_date}&end_date=${end_date}`,
      getToken()
    );
    return data;
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const getMasterView = async (
  page: number,
  limit: number,
  start_date: any,
  end_date: any,
  pan?: any,
  loanId?: any,
  borrower_id?: any,
  mobile_number?: any,
  companyEmpId?: any,
  company?: any
) => {
  try {
    let url = `?page=${page}&limit=${limit}&start_date=${start_date}&end_date=${end_date}`;

    if (pan) {
      url = url + `&pan=${pan}`;
    }
    if (loanId) {
      url = url + `&loanId=${loanId}`;
    }
    if (borrower_id) {
      url = url + `&borrower_id=${borrower_id}`;
    }
    if (mobile_number) {
      url = url + `&mobile_no=${mobile_number}`;
    }
    if (companyEmpId) {
      url = url + `&emp_id=${companyEmpId}`;
    }
    if (company) {
      url = url + `&company=${company}`;
    }
    const { data } = await axios.get(
      `${config.base_url}/loan/appraisal/masterview${url}`,
      getToken()
    );

    return data;
  } catch (err) {
    window.location.href = "/no_cred";
  }
};

export const getRepaymentsDetail = async (request_id: any) => {
  try {
    //https://api-staging.entitled.co.in/repayment/1
    const { data } = await axios.get(
      `${config.base_url}/repayment/${request_id}`,
      getToken()
    );
    return data;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const createRepaymentExport = async (start_date: any, end_date: any) => {
  try {
    const headers: any = getToken();
    {
      //@ts-ignore
      headers["headers"]["responseType"] = "blob";
    }
    const result = await axios.post(
      `${config.base_url}/repayment/utils/create-export?start_date=${start_date}&end_date=${end_date}`,
      {},
      headers
    );
    //  await logData("export_nbfc",{data,start_date,end_date,status})
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${new Date().toISOString()}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
    return "success";
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const createLoanReportExport = async (id: any,sendUser:boolean) => {
  try {
    const headers: any = getToken();
    const result = await axios.post(
      `https://prod.entitled.co.in/report-generate`,
      { loan_id: id ,send_user:sendUser},
      headers
    );

    const url = result.data.body.data;
    if(!sendUser){
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${new Date().toISOString()}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
    return "success";
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};


export const createLoanNocExport = async (id: any) => {
  try {
    const headers: any = getToken();
    const result = await axios.post(
      `https://prod.entitled.co.in/report-generate`,
      { loan_id: id ,noc:true},
      headers
    );

    const url = result.data.body.data;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${new Date().toISOString()}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
    return "success";
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const createSlackAlert = async (e: any, operation: any) => {
  let environment =
    process.env.REACT_APP_STAGE === "production" ? "production" : "staging";
  let block = [
    {
      type: "header",
      text: {
        type: "plain_text",
        text: "Error request",
      },
    },
    {
      type: "section",
      fields: [
        {
          type: "mrkdwn",
          text: "*Request:*\n" + environment + "-" + operation,
        },
        {
          type: "mrkdwn",
          text: "*Error:*\n" + e + JSON.stringify(e),
        },
      ],
    },
    {
      type: "section",
      fields: [
        {
          type: "mrkdwn",
          text: "*Timestamp:*\n" + new Date(),
        },
      ],
    },
    {
      type: "section",
      fields: [
        {
          type: "mrkdwn",
          text: "*Client:*\n" + "DASHBOARD",
        },
      ],
    },
  ];

  const body = `channel=C039WFBF42Y&blocks=${JSON.stringify(
    block
  )}&token=xoxb-2454793236135-3328581606710-TxL7tRdCAVHIoCn1QjJWCaZA`;
  await axios.post("https://slack.com/api/chat.postMessage", body, {
    timeout: 10000,
    transformRequest(data, headers) {
      //@ts-ignore
      delete headers.common["Content-Type"];
      return data;
    },
  });
};

export const invokeArthmateStateMachine = async (data: any) => {
  try {
    const result = await axios.post(
      `${config.base_url}/loan/arthmate/trigger`,
      data,
      getToken()
    );
    // await logData("update_bulk_loan_request_status",data)
    return result["data"];
  } catch (err) {
    await createSlackAlert(err, "UPDATE LOAN REQUEST");
    window.location.href = "/no_cred";
  }
};

export const deleteResources = async (emp_id: number, type: string) => {
  try {
    const auth = getToken();
    const { data } = await axios.delete(
      `${config.base_url}/employee/resources/${emp_id}`,
      {
        headers: {
          Authorization: auth.headers.Authorization,
        },
        data: {
          type: type,
        },
      }
    );
    return data;
  } catch (err) {
    // window.location.href = "/no_cred"
  }
};

export const refreshNotif = async (mobile: string) => {
	try {
	  const res = await axios.post('https://6cnun4duga.execute-api.ap-south-1.amazonaws.com/dev/zoko', {
		type: '24_hr_trigger',
		mobile
	  });
	  return 'ok'
	} catch (err) {
	  return ''
	}
  }

export const bankValidate = async (request_id: any) => {
    try {
      const { data } = await axios.post(
        `${config.base_url}/loan/pennydrop/request`,
        { request_id: request_id },
        getToken()
      );
      message.success("Bank Validated Successfully");
      return data;
    } catch (err) {
      message.error("Bank Validation Failed");
      // window.location.href = "/no_cred"
    }
};
export const pushToThirdParty = async (loan_id: any) => {
    try {
      const { data } = await axios.post(
        `${config.base_url}/loan/third-party-lead`,
        { loan_id: loan_id },
        getToken()
      );
      if(data.status == false){
        message.error(data?.message)
      }
      else message.success("Pushed to third Party Successfully");
      return data;
    } catch (err) {
      message.error("Failed to push to third party");
      // window.location.href = "/no_cred"
    }
};
export const createSingleCharge = async (params: any) => {
    try {
      const { data } = await axios.post(
        `${config.base_url}/loan/createCharge`,
        params,
        getToken()
      );
      if(data?.status == false){
        message.error(data?.message)
      }
      else message.success("Charge created Successfully");
      return data;
    } catch (err) {
      message.error("Failed to create charge");
      // window.location.href = "/no_cred"
    }
};
export const triggerKYC_bot = async (params: any) => {
    try {
      const { data } = await axios.post(
        `${config.base_url}/utils/trigger-kyc-bot`,
        params,
        getToken()
      );
      return data;
    } catch (err) {
      message.error("Failed to trigger KYC bot");
      throw err;
      // window.location.href = "/no_cred"
    }
};

export const sendNotification = async (emp_id: any,event:any,args:any) => {
  try {
    let data = JSON.stringify({
      "messageId": "globalId",
      "event": event,
      "lob": "credit",
      "priority": "high",
      "receiver_id": String(emp_id),
      "send_time": new Date().toLocaleString(),
      "channels": [
        {
          "type": "whatsapp",
          "args":args
        }
      ]
    });
    const result = await axios.post(
      `https://prod.entitled.co.in/notifcation`,
      data,
      {
        //@ts-ignore
        headers: {'Content-Type': 'application/json' }
      }
    );
    // await logData("update_bulk_loan_request_status",data)

    return result["data"];
  } catch (err) {
    console.log(err)
   // await createSlackAlert(err, "UPDATE LOAN REQUEST");
   // window.location.href = "/no_cred";
  }
};
import {
  Descriptions,
  Badge,
  Typography,
  Card,
  Input,
  Spin,
  message,
  Row,
  Col,
  Button,
  Select,
  Modal,
  Switch,
  Table,
  Statistic,
  Drawer,
  Space
} from "antd";

import { useEffect, useState, useRef } from "react";
import moment from "moment";
import type { ColumnsType } from "antd/es/table";
import { HighlightOutlined, MehOutlined, DeleteTwoTone } from "@ant-design/icons";

import usePrevious from "../utitlities/previous";
import { useDispatch, useSelector } from "react-redux";
import {
  appraisalNotifications,
  createLoanNocExport,
  createLoanReportExport,
  enach,
  esign,
  deleteRepayment,
} from "../utitlities/request";
import { MainReducer } from "../types";
import { TopHeader } from "./common/topHeader";
import { fetchRepayment } from "../state/action-creators";
import { useLocation } from "react-router-dom";
import '../styles/buttons.css';
const { Title, Paragraph, Text, Link } = Typography;
const { Option } = Select;

export function RepaymentDetail(props: any) {
  const { innerWidth: width, innerHeight: height } = window;
  const search = useLocation().search;
  const [loading, setLoading] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalDeleteId, setIsModalDeleteId] = useState<number>(-1);
  const columns = [
    {
      title: "EMI Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];
  const onClose = () => {
    setIsDrawer(false);
  };

  const handleDeleteRepayment = async function () {
    try {
      if(isModalDeleteId !== -1){
        setLoading(true);
        await deleteRepayment(isModalDeleteId);
        dispatch(fetchRepayment(id));
        setLoading(false);
      }
    } catch (error) {
      message.error('Repayment Could not be deleted');
      setLoading(false);
    }
  }

  const columns2: ColumnsType<any> = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Received Date",
      dataIndex: "received_date",
      key: "received_date",
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
  ];
  const data2 = [
    {
      amount: 1000,
      payout_date: "23/10/12",
      source: "Cashfree",
    },
    {
      amount: 2000,
      payout_date: "23/10/12",
      source: "Deductions",
    },
    {
      amount: 1000,
      payout_date: "23/10/12",
      source: "Manual",
    },
    {
      amount: 1000,
      payout_date: "23/10/12",
      source: "Cashfree",
    },
    {
      amount: 1000,
      payout_date: "23/10/12",
      source: "Cashfree",
    },
    {
      amount: 1000,
      payout_date: "23/10/12",
      source: "Cashfree",
    },
  ];
  // dispatch
  const dispatch = useDispatch();
  const id = new URLSearchParams(search).get("id");
  // drawer
  const [isDrawer, setIsDrawer] = useState(false);
  const showDrawer = () => {
    setIsDrawer(true);
  };
  // repayment from redux store
  const repayment = useSelector((state: MainReducer) => {
    return state.loan.repayment.body;
  });
  const repaymentStatus = useSelector((state: MainReducer) => {
    return state.loan.repayment.status;
  });
  // variables top card
  const [accountNo, setAccountNo] = useState("");
  const [approvedAmount, setApprovedAmount] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [disbursalDate, setDisbursalDate] = useState("");
  const [firstDate, setFirstDate] = useState("");
  const [firstName, setFirstName] = useState("");
  const [mobile, setMobile] = useState("");
  const [installmentAmount, setInstallMentAmount] = useState("");
  const [installmentCount, setInstallmentCount] = useState("");
  const [interest, setInterest] = useState("");
  const [lastName, setLastName] = useState("");
  const [loanOpsId, setLoanOpsId] = useState("");
  const [nbfcLoanId, setnbfcLoanId] = useState("");
  const [outstandingAmount, setoutstandingAmount] = useState("");
  const [monthlySalary, setMonthlySalary] = useState("");
  const [pan, setPan] = useState("");
  const [preEmiInterest, setPreEmiInterest] = useState("");
  const [nbfcName, setNbfcName] = useState("");
  const [category, setCategory] = useState("");
  const [loanStatus, setLoanStatus] = useState("");
  const [overdue, setOverdue] = useState("");
  const user = JSON.parse(localStorage?.getItem("user") || "");

  if(user.role && user?.role === "super_admin"){
    columns2.push({
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record, index) => (
        <Space size="middle">
          <DeleteTwoTone twoToneColor="#b53328" style={{ fontSize: "20px" }}
             onClick={ () => {
              setIsModalDelete(true);
              setIsModalDeleteId(record.id);
              }} />
        </Space>
      ),
    });
  }

  // set summary

  const [emi_schedule, setEmiSchedule] = useState<any>([]);
  const [repayment_schedule, setRepaymentSchedule] = useState<any>([]);

  useEffect(() => {
    dispatch(fetchRepayment(id));
  }, []);
  useEffect(() => {
    if (repaymentStatus) {
      // set the summary
      setSummary(repayment.summary);
      for (let index = 0; index < repayment.emi_schedule.length; index++) {
        const element = repayment.emi_schedule[index];
        element.date = moment(element.date).format("DD-MMM-YYYY");
      }

      for (
        let index = 0;
        index < repayment.repayments_schedule.length;
        index++
      ) {
        const element = repayment.repayments_schedule[index];
        element.received_date = moment(element.received_date).format(
          "DD-MMM-YYYY"
        );
      }
      setEmiSchedule(repayment.emi_schedule);
      setRepaymentSchedule(repayment.repayments_schedule);
    }
  }, [repaymentStatus, repayment]);

  const setSummary = (summary: any) => {
    setAccountNo(summary.account_no);
    setApprovedAmount(summary.approved_amount);
    setCompanyName(summary.company_name);
    setDisbursalDate(moment(summary.disbursal_date).format("DD-MMM-YYYY"));
    setFirstDate(moment(summary.first_date).format("DD-MMM-YYYY"));
    setFirstName(summary.first_name);
    setMobile(summary.mobile_no_1);
    setInstallMentAmount(summary.installment_amt);
    setInstallmentCount(summary.installment_count);
    setInterest(summary.interest);
    setLastName(summary.last_name);
    setLoanOpsId(summary.loan_ops_id);
    setnbfcLoanId(summary.arthmate_post_loan_id || "Unavailable");
    setoutstandingAmount(summary.outstanding_amount);
    setMonthlySalary(summary.monthly_salary);
    setPan(summary.pan);
    setPreEmiInterest(summary.pre_emi_interest);
    setNbfcName(summary.nbfc_name);
    setCategory(summary.category);
    setLoanStatus(summary.loanStatus);
    setOverdue(summary.overdue);
  };
  return (
    <div>
      {repaymentStatus === true ? (
        <div
          style={{
            backgroundColor: "#f0f0f0",
            height: height,
            width: width * 0.9,
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Row>
            <Col span={24}>
              <Card
                style={{
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
                  margin: "1%",
                  overflowY: "scroll",
                }}
              >
                <Row>
                  <Col span={20}></Col>
                  <Col span={2}>
                    <Button
                      type="primary"
                      className="antd-button"
                      style={{ backgroundColor: "#1B875A", border: 0, marginBottom: "5%" }}
                      onClick={showDrawer}
                    >
                      Update Actions
                    </Button>
                  </Col>
                </Row>
                <Descriptions
                  title="Borrower and Loan Details "
                  bordered
                  style={{ marginTop: "-2%" }}
                >
                  <Descriptions.Item label={"Account No"} span={1}>
                    {accountNo}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Approved Amount"} span={1}>
                    {approvedAmount}
                  </Descriptions.Item>
                  <Descriptions.Item label={"PAN"} span={1}>
                    {pan}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Employee Name"} span={1}>
                    {firstName} {lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Mobile Number"} span={1}>
                    {mobile}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Employer Name"} span={1}>
                    {companyName}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Disbursal Date"} span={1}>
                    {disbursalDate}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Emi First Date"} span={1}>
                    {firstDate}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Installment Amount"} span={1}>
                    {installmentAmount}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Period"} span={1}>
                    {installmentCount}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Interest"} span={1}>
                    {interest}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Pre Emi Interest"} span={1}>
                    {preEmiInterest}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Last Name"} span={1}>
                    {lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Loan Ops Id"} span={1}>
                    {loanOpsId}
                  </Descriptions.Item>
                  
                  <Descriptions.Item
                    label={"Outstanding Amount"}
                    span={1}
                    style={{ fontWeight: "bolder", fontSize: "20px" }}
                  >
                    {outstandingAmount}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Report"} span={1}>
                    <Button
                      type="primary"
                      className="antd-button"
                      style={{ backgroundColor: "#1B875A", border: 0 }}
                      block
                      onClick={() => {
                        createLoanReportExport(id, false);
                      }}
                    >
                      Generate Report
                    </Button>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={"Loan Status"}
                    span={1}
                  >{loanStatus}</Descriptions.Item>
                  <Descriptions.Item
                    label={"Overdue"}
                    span={1}
                  >{overdue}</Descriptions.Item>
                  {user?.role != "agency" && user?.role != "collection" ? (<Descriptions.Item label={"User Report"} span={1}>
                    <Button
                      type="primary"
                      className="antd-button"
                      style={{ backgroundColor: "#1B875A", border: 0 }}
                      block
                      onClick={() => {
                        createLoanReportExport(id, true);
                      }}
                    >
                      Send Report
                    </Button>
                  </Descriptions.Item>) : ""}
                  <Descriptions.Item label={"NBFC Name"} span={1}>
                    {nbfcName}
                  </Descriptions.Item>
                  <Descriptions.Item label={"Category"} span={1}>
                    {category}
                  </Descriptions.Item>
                  <Descriptions.Item label={"NBFC Loan ID"} span={1}>
                    {nbfcLoanId}
                  </Descriptions.Item>
                  {
                  Number(outstandingAmount) <= 100 && user.role !== "agency" ? (
                    <Descriptions.Item label={"NOC"} span={1}>
                      <Button
                        type="primary"
                        className="antd-button"
                        block
                        onClick={() => {
                          createLoanNocExport(id);
                        }}
                      >
                        Generate Noc
                      </Button>
                    </Descriptions.Item>
                  ) : null
                  }
                </Descriptions>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Card style={{ margin: "1%" }}>
                <h6>EMI SCHEDULE</h6>
                <Table
                  columns={columns}
                  dataSource={emi_schedule}
                  scroll={{ x: true }}
                />
              </Card>
            </Col>

            <Col span={12}>
              <Card style={{ margin: "1%" }}>
                <h6>REPAYMENT SCHEDULE</h6>
                <Spin spinning={loading} tip="Loading...">
                  <Table columns={columns2} dataSource={repayment_schedule} />
                </Spin>
              </Card>
            </Col>
            <Modal
              title="Alert"
              open={isModalDelete}
              onOk={() => {
                handleDeleteRepayment();
                setIsModalDeleteId(-1);
                setIsModalDelete(false);
              }}
              onCancel={() => {
                setIsModalDeleteId(-1);
                setIsModalDelete(false);
              }}
            >
              <p>Are you sure you want to delete this Repayment ?</p>
            </Modal>
          </Row>
        </div>
      ) : (
        ""
      )}

      <Drawer
        title="Actions"
        placement="right"
        onClose={onClose}
        visible={isDrawer}
        width={550}
      >
        <>
          {/* {type == "appraisal" ? (
                <Row>
                  <Col span={24}>
                    <Card style={{ margin: "1%", marginTop: "1%" }}>
                      <Button
                        shape="round"
                        type="primary"
                        style={{
                          marginTop: "5%",
                          marginBottom: "5%",
                          width: "100%",
                        }}
                        onClick={() => {
                          eNach();
                        }}
                      >
                        Generate Enach
                      </Button>
                    </Card>
                  </Col>

                  <Col span={24}>
                    <Card style={{ margin: "1%", marginTop: "1%" }}>
                      <Button
                        shape="round"
                        type="primary"
                        style={{
                          marginTop: "5%",
                          marginBottom: "5%",
                          width: "100%",
                        }}
                        onClick={() => {
                          eSign();
                        }}
                      >
                        Generate Esignature
                      </Button>
                    </Card>
                  </Col>

                  <Col span={24}>
                    <Card style={{ margin: "1%", marginTop: "1%" }}>
                      <Button
                        shape="round"
                        type="primary"
                        style={{
                          marginTop: "5%",
                          marginBottom: "5%",
                          width: "100%",
                        }}
                        onClick={() => {
                          appraisalNotify();
                        }}
                      >
                        Appraisal Notifications
                      </Button>
                    </Card>
                  </Col>
                </Row>
              ) : (
                ""
              )} */}
        </>
      </Drawer>
    </div>
  );
}
